import {
    ArrowUpIcon,
    MailIcon,
    YoutubeIcon,
    MapPinIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    GithubIcon,
    GitlabIcon,
    UserIcon,
    PhoneIcon,
    MessageCircleIcon
} from 'vue-feather-icons';

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import { contactInsert } from "@/services/contactService";
/**
 * Page-contact-detail component
 */
export default {
    data() {
        return {
            name: "",
            email: "",
            tel: "",
            message: "",
            modalContact: false,
            textResult: "",
            overlay: false,
        }
    },
    components: {
        Navbar,
        Switcher,
        ArrowUpIcon,
        MailIcon,
        MapPinIcon,
        FacebookIcon,
        InstagramIcon,
        TwitterIcon,
        LinkedinIcon,
        GithubIcon,
        YoutubeIcon,
        GitlabIcon,
        UserIcon,
        PhoneIcon,
        MessageCircleIcon
    },
    methods: {
        actionContactInsert() {
            this.overlay = true
            contactInsert({
                name: this.name,
                email: this.email,
                tel: this.tel,
                message: this.message,
            }).then((data) => {
                this.modalContact = true
                this.textResult = "ส่งข้อความสำเร็จ"
            })
                .catch((err) => {
                    this.modalContact = true
                    this.textResult = "ส่งข้อความไม่สำเร็จ"
                }).finally(() => {
                    this.overlay = false
                });
        },
    }

}