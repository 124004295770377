import axios from "axios"

const contactInsert = function (data) {
    var body = {
        name: data.name,
        email: data.email,
        tel: data.tel,
        message: data.message,
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: '/contact/manage/insert',
            data: body
        }).then(resp => {

            if (resp.data.status == true) {
                resolve(resp.data.result)
            } else {

                resolve(result)
            }
        }).catch(err => {
            reject(err)
        })
    })
}

export { contactInsert }